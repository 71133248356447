import { defineComponent } from "vue";

export default defineComponent({
  name: "Nasarawa Investment Summit",
  components: {},
  methods: {
    goLive() {
      window.location.replace('https://youtu.be/c6fO0QhRBxU')
    },
    summitProgramme() {
      window.open('https://nasidainvestment-my.sharepoint.com/:b:/g/personal/summit_nasida_na_gov_ng/EXN-5TiuFxlMsDzj2Z7xU3kBE8KNF9P4D4chhXptbDwwpg?e=ZECmyc', '_blank')
    },
    dealRoomRegister() {
      window.open('https://forms.office.com/r/VNAiWdFjvm', '_blank')
    },
    dealRooomFindOutMore() {
      window.open('https://nasidainvestment-my.sharepoint.com/:b:/g/personal/summit_nasida_na_gov_ng/ERix1jn2olVDp9fR_TV32IUBzE6YAz7fGpB0MmYVyxYDtQ?e=SNmdc1', '_blank')
    },
    msmeRegister() {
      window.open('https://forms.gle/rhSpyZMHLGTomWuW9', '_blank')
    },
    msmeFindOutMore() {
      window.open('https://nasidainvestment-my.sharepoint.com/:b:/g/personal/summit_nasida_na_gov_ng/EbEKg_kmqJZJgYL_CFp1fO4BXsheU5yG44Wz2PEUINOrWQ?e=VaYRub', '_blank')
    },
  }
});
