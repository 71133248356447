<template>
  <div
    id="investment-summit"
    class="
      font-grold
      px-6
      pb-10
      md:px-10
      lg:px-12
      xl:px-28
      2xl:max-w-screen-xl 2xl:mx-auto 2xl:px-0
      w-full
    "
  >
    <section id="hero" class="grid lg:grid-cols-7 lg:h-80vh">
      <div class="mt-4 lg:mt-20 space-y-8 lg:col-span-4 order-2 lg:order-1">
        <p class="text-xl date">
          MAY 11TH <span class="font-sans"> - </span> 12TH
        </p>
        <div class="space-y-2">
          <h1 class="tag">Diamond In The Rough</h1>
          <h3 class="text-2xl">THE MAKING OF A NEW INVESTMENT FRONTIER</h3>
        </div>
        <p class="text-xl tracking-widest">LAFIA, NASARAWA STATE</p>
        <div class="actions flex flex-wrap items-center gap-4">
          <button class="secondary flex items-center space-x-2" @click="goLive()">
            <span>JOIN US LIVE</span> <Icon width="32" icon="ant-design:youtube-filled"/> 
          </button>
        </div>
      </div>
      <div class="lg:col-span-3 order-1 lg:order-2">
        <div class="absolute hex-image hidden lg:block">
          <img class="" src="@/assets/images/hex-image-full.png" alt="" />
        </div>
        <div class="w-52 mb-8 lg:hidden">
          <img src="@/assets/images/hex-image.png" alt="" />
        </div>
      </div>
    </section>
    <section id="about" class="mt-20 space-y-10">
      <div class="space-y-6">
        <h4 class="tag subheader">About NIS</h4>
        <h5 class="text-2xl lg:text-3xl">
          The Nasarawa Investment Summit 2022 <br />
          “Diamond in the Rough, The Making of a New Investment Frontier”
        </h5>

        <div class="space-y-4 text-justify lg:text-xl font-light lg:font-thin">
          <p>
            Nasarawa State has shown great promise over the years by overcoming
            numerous roadblocks as we inch towards our vision of becoming
            Nigeria<span class="font-reset">'</span>s top investment powerhouse.
            As a State, we have been very deliberate about stimulating our
            economy, building our people<span class="font-reset">'</span>s
            productive capacities, and creating wealth and jobs for them. This
            is the basis of the Nasarawa Economic Development Strategy
            <span class="font-reset">(</span>NEDS<span class="font-reset"
              >)</span
            >
            <span class="font-reset"> (</span>2019
            <span class="font-reset">-</span> 2023<span class="font-reset"
              >)</span
            >, which is a testament to our collective commitment to implement a
            practicable economic transformation strategy for the State, as
            promised during our campaign. It is founded on a vision to sustain
            accelerated growth of the Nasarawa economy driven by the private
            sector and translating this to a clear improvement in citizens<span
              class="font-reset"
              >'</span
            >
            lives and the achievement of other key development outcomes.
          </p>
          <p>
            We are therefore pleased to host the inaugural Nasarawa Investment
            Summit. The summit will serve as a platform to present credible and
            bankable investment opportunities that are market<span
              class="font-reset"
              >-</span
            >ready for investor engagement. It will also foster a closer
            collaboration and partnership between the private sector and the
            government in building a stronger business environment. With
            participants from diverse backgrounds from across the globe, this is
            a platform to engage with Government and Business leaders to invest
            and do business in Nasarawa State.
          </p>
        </div>
      </div>
      <div class="space-y-16">
        <h4 class="tag subheader">Expected Outcomes from NIS</h4>
        <div class="grid lg:grid-cols-2 gap-16">
          <div class="card">
            <div class="image">
              <img src="../../assets/icons/nasiv/Build.svg" />
            </div>
            <h5>Build</h5>
            <p>
              Build and enhance private sector involvement in good governance
              and policy making, especially as it involves the delivery of the
              Nasarawa Economic Development Strategy
              <span class="font-reset">(</span>NEDS<span class="font-reset"
                >)</span
              >, and also provide an inclusive platform and methodology that
              promotes the participation of all key stakeholders in NEDS
              implementation.
            </p>
          </div>
          <div class="card">
            <div class="image">
              <img src="../../assets/icons/nasiv/Inform.svg" />
            </div>
            <h5>Inform</h5>
            <p>
              Inform ongoing efforts to facilitate technology development and
              transfer of technology to Nasarawa.
            </p>
          </div>
          <div class="card">
            <div class="image">
              <img src="../../assets/icons/nasiv/Partner.svg" />
            </div>
            <h5>Partner</h5>
            <p>
              Form development partnerships with the public and private sector
              that will stimulate production and have a developmental impact
              across the state
            </p>
          </div>
          <div class="card">
            <div class="image">
              <img src="../../assets/icons/nasiv/Identify.svg" />
            </div>
            <h5>Identify</h5>
            <p>
              Identify impediments, obstacles to investments in Nasarawa from
              the perspectives of governments, investors, international finance
              institutions, and businesses.
            </p>
          </div>
          <div class="card">
            <div class="image">
              <img src="../../assets/icons/nasiv/Showcase.svg" />
            </div>
            <h5>Showcase</h5>
            <p>
              Showcase multi<span class="font-reset">-</span>sectoral
              project<span class="font-reset">-</span>specific opportunities
              available to investors in the state.
            </p>
          </div>
          <div class="card">
            <div class="image">
              <img src="../../assets/icons/nasiv/Attract.svg" />
            </div>
            <h5>Attract</h5>
            <p>
              Attract investment from local and foreign investors to facilitate
              projects and programs that will unlock sustainable socio<span
                class="font-reset"
                >-</span
              >economic development of Nasarawa.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="plenaries" class="mt-20 space-y-8">
      <h4 class="tag subheader">Plenaries</h4>
      <div class="line-divider"></div>
      <div class="plenary">
        <div class="plenary-image">
          <div class="plenary-text">
            <h4>
              Nasarawa Means Business: The Urgency Of Delivering Sustained
              Economic Growth
            </h4>
          </div>
          <div class="plenary-overlay"></div>
          <img
            src="../../assets/images/nis/economic-growth.png"
            alt="Nasarawa Means Business: The Urgency Of Delivering Sustained Economic Growth "
          />
        </div>
        <div class="flex flex-col justify-between space-y-4">
          <p class="lg:text-xl font-light lg:font-thin">
            The Nasarawa State Government has been relentless in its efforts to
            design and implement investment and promotion strategies to aid its
            policy making and facilitate its goal of making Nasarawa State an
            investment destination. This Session highlights the investment and
            promotion strategies towards attracting private sector investment
            into Nasarawa State and the available investment opportunities.
          </p>
          <router-link to="#speakers" class="w-max">
            <button class="secondary w-max">
              <p>View Speaker List</p>
            </button>
          </router-link>
        </div>
      </div>
      <div class="line-divider"></div>
      <div class="plenary">
        <div class="plenary-image">
          <div class="plenary-text">
            <h4>
              Collaborating for Growth: a discussion on how sub<span
                class="font-reset"
                >-</span
              >nationals can utilize their shared strengths
            </h4>
          </div>
          <div class="plenary-overlay"></div>
          <img
            src="../../assets/images/nis/land.png"
            alt="Collaborating for Growth: a discussion on how sub-nationals can
              utilize their shared strengths"
          />
        </div>
        <div class="flex flex-col justify-between space-y-4">
          <p class="lg:text-xl font-light lg:font-thin">
            This plenary aims to unlock Strategies directed at unexplored
            funding mechanisms for sub<span class="font-reset">-</span>national
            governments through insights into their capacity to unlock wealth
            bottled in Nigeria’s outdated land tenure system and partnerships
            with Development finance institutions to broadening their support to
            states as veritable centers of economic development in Nigeria.
          </p>
          <router-link to="#speakers" class="w-max">
            <button class="secondary w-max">
              <p>View Speaker List</p>
            </button>
          </router-link>
        </div>
      </div>
      <div class="line-divider"></div>
      <div class="plenary">
        <div class="plenary-image">
          <div class="plenary-text">
            <h4>
              Innovative solutions to complex problems: Financing Infrastructure
              in Sub<span class="font-reset">-</span>Nationals
            </h4>
          </div>
          <div class="plenary-overlay"></div>
          <img
            src="../../assets/images/nis/infrastructure.png"
            alt="Innovative solutions to complex problems: Financing Infrastructure in Sub-Nationals"
          />
        </div>
        <div class="flex flex-col justify-between space-y-4">
          <p class="lg:text-xl font-light lg:font-thin">
            The plenary is focused on exploring innovative approaches for
            financing the delivery of sustainable infrastructure at the sub<span
              class="font-reset"
              >-</span
            >national levels, given the prevailing fiscal constraints within the
            sub<span class="font-reset">-</span>nationals and the opportunities
            available in the private sector.
          </p>
          <router-link to="#speakers" class="w-max">
            <button class="secondary w-max">
              <p>View Speaker List</p>
            </button>
          </router-link>
        </div>
      </div>
      <div class="line-divider"></div>
      <div class="plenary">
        <div class="plenary-image">
          <div class="plenary-text">
            <h4>Project Nasarawa: From Roundtable to Groundbreaking</h4>
          </div>
          <div class="plenary-overlay"></div>
          <img
            src="../../assets/images/nis/round-table.png"
            alt="Project Nasarawa: From Roundtable to Groundbreaking"
          />
        </div>
        <div class="flex flex-col justify-between space-y-4">
          <p class="lg:text-xl font-light lg:font-thin">
            This plenary will educate the audience on how deals are shaped
            within the necessary frameworks and support structures available for
            newcomers in the State. It shall capture the perspectives and
            experiences of different private investors already present in the
            State
          </p>
          <router-link to="#speakers" class="w-max">
            <button class="secondary w-max">
              <p>View Speaker List</p>
            </button>
          </router-link>
        </div>
      </div>
      <div class="line-divider"></div>
      <div class="plenary">
        <div class="plenary-image">
          <div class="plenary-text">
            <h4>
              Promoting a knowledge<span class="font-reset">-</span>based
              economy as a critical Pillar for Growth
            </h4>
          </div>
          <div class="plenary-overlay"></div>
          <img
            src="../../assets/images/nis/knowledge.png"
            alt="Promoting a knowledge-based economy as a critical Pillar for Growth"
          />
        </div>
        <div class="flex flex-col justify-between space-y-4">
          <p class="lg:text-xl font-light lg:font-thin">
            This session reviews the various knowledge<span class="font-reset"
              >-</span
            >based economic models that have been adopted in global developed
            and developing economies and also propose strategies towards
            building a knowledge<span class="font-reset">-</span>based economy
            for sustainable economic growth in Nasarawa State.
          </p>
          <router-link to="#speakers" class="w-max">
            <button class="secondary w-max">
              <p>View Speaker List</p>
            </button>
          </router-link>
        </div>
      </div>
      <div class="line-divider"></div>
      <div class="plenary">
        <div class="plenary-image">
          <div class="plenary-text">
            <h4>Funding, fostering and fueling SME and ICT growth</h4>
          </div>
          <div class="plenary-overlay"></div>
          <img
            src="../../assets/images/nis/ict-sme.png"
            alt="Funding, fostering and fueling SME and ICT growth"
          />
        </div>
        <div class="flex flex-col justify-between space-y-4 space-y-6">
          <div class="space-y-4">
            <p class="lg:text-xl font-light lg:font-thin">
              The Nasarawa State Government through NASIDA its investment
              driver; has formulated sustainable business friendly policies to
              support and enable the private sector and young entrepreneurs to
              thrive through Promoting the SMEs.
            </p>
            <p class="lg:text-xl font-light lg:font-thin">
              The Plenary will seek to address 3
              <span class="font-reset">(</span>three<span class="font-reset"
                >)</span
              >
              critical issues:
            </p>
            <ol class="lg:text-xl font-light lg:font-thin space-y-3">
              <li>
                <span class="font-reset">-</span> Identify the challenges
                confronting the SME<span class="font-reset">/</span>
                Entrepreneurship community and seek ways to jumpstart and
                accelerate the Community in Nasarawa.
              </li>
              <li>
                <span class="font-reset">-</span> Maximizing the ICT
                Opportunities in the State through the acceleration and
                promotion of the Nasarawa Technology Village
                <span class="font-reset">(</span>NTV<span class="font-reset"
                  >)</span
                >
              </li>
              <li>
                <span class="font-reset">-</span> Serve as an invitation to
                non<span class="font-reset">-</span>Nasarawa State Indigenes to
                explore the opportunities in the State and access its benefits.
              </li>
            </ol>
          </div>
          <router-link to="#speakers" class="w-max">
            <button class="secondary w-max">
              <p>View Speaker List</p>
            </button>
          </router-link>
        </div>
      </div>
      <div class="line-divider"></div>
    </section>
    <section id="msme-challenge" class="mt-20">
      <div class="large-image">
        <div class="large-text space-y-4">
          <h3 class="tag">MSME Challenge</h3>
          <p class="font-light lg:font-thin text-lg lg:text-2xl">
            The Nasarawa MSME Challenge 2022, an initiative of the Nasarawa
            State Government in collaboration with local and international
            development partners, is a pitch competition targeted at providing
            SMEs with access to capital and adequate government and private
            sector support that will enable them to establish, build and grow
            their business.
          </p>
          <div
            class="
              flex flex-col
              lg:flex-row
              space-y-6
              lg:space-y-0 lg:items-center lg:space-x-8
            "
          >
            <button @click="msmeRegister()" class="secondary w-max">
              <p>Register Here</p>
            </button>
            <button @click="msmeFindOutMore()" class="secondary w-max">
              <p>Find out More</p>
            </button>
          </div>
        </div>
        <div class="large-overlay"></div>
        <img
          src="../../assets/images/nis/entrepreneurs.png"
          alt="Nasarawa Means Business: The Urgency Of Delivering Sustained Economic Growth "
        />
      </div>
    </section>
    <section id="deal-room" class="mt-20">
      <div class="large-image">
        <div class="large-text space-y-4">
          <h3 class="tag">Deal Room</h3>
          <p class="font-light lg:font-thin text-lg lg:text-2xl">
            The Nasarawa Investment Summit 2022 Deal Room
            <span class="font-reset">(</span>the
            <span class="font-reset">#</span>NIS2022 Deal Room<span
              class="font-reset"
              >)</span
            >
            is a showcase of bankable opportunities and a strategic matchmaking
            platform that will convene stakeholders from the Nigerian investment
            eco<span class="font-reset">-</span>system to facilitate
            partnerships and investments.
          </p>
          <div
            class="
              flex flex-col
              lg:flex-row
              space-y-6
              lg:space-y-0 lg:items-center lg:space-x-8
            "
          >
            <button @click="dealRoomRegister()" class="secondary w-max">
              <p>Register To Participate</p>
            </button>
            <button @click="dealRooomFindOutMore()" class="secondary w-max">
              <p>Find out More</p>
            </button>
          </div>
        </div>
        <div class="large-overlay"></div>
        <img
          src="../../assets/images/nis/deal-room.png"
          alt="Nasarawa Means Business: The Urgency Of Delivering Sustained Economic Growth "
        />
      </div>
    </section>
    <section id="speakers" class="mt-20 space-y-8">
      <div class="flex items-center">
        <h4 class="tag subheader w-2/5">Key Speakers</h4>
        <div class="line-divider w-full"></div>
      </div>
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div class="speaker">
          <img src="../../assets/images/nis/mahana.png" alt="" />
        </div>
        <div class="speaker">
          <img src="../../assets/images/nis/dangote.png" alt="" />
        </div>
        <div class="speaker">
          <img src="../../assets/images/nis/lazarus.png" alt="" />
        </div>
        <div class="speaker">
          <img src="../../assets/images/nis/ajayi.png" alt="" />
        </div>
        <div class="speaker">
          <img src="../../assets/images/nis/muhtar.png" alt="" />
        </div>
        <div class="speaker">
          <img src="../../assets/images/nis/ola.png" alt="" />
        </div>
        <div class="speaker">
          <img src="../../assets/images/nis/obaseki.png" alt="" />
        </div>
        <div
          class="
            hidden
            md:space-x-4 md:col-span-1 md:flex md:items-center
            lg:col-span-2
          "
        >
          <div class="line-divider w-full"></div>
          <button class="secondary w-full lg:w-44">
            <p>And Much More</p>
          </button>
        </div>
      </div>
    </section>
    <section class="mt-20">
      <div class="relative new-frontier overflow-hidden lg:h-full">
        <div class="absolute w-full h-full p-10 lg:p-20 flex flex-col justify-between">
          <div class="lg:grid grid-cols-3 gap-6 items-center">
            <img
              class="col-span-1 object-contain"
              src="../../assets/icons/nasiv-logo.svg"
              alt=""
            />
            <div class="col-span-2">
              <h2 class="text-2xl tracking-widest">
                THE MAKING OF A NEW INVESTMENT FRONTIER
              </h2>
              <div class="mt-6">
                <p class="text-xl font-light tracking-widest">
                  LAFIA, NASARAWA STATE
                </p>
                <p class="text-xl font-light tracking-widest">
                  MAY 11TH <span class="font-reset">-</span> 12TH
                </p>
              </div>
            </div>
          </div>
          <div
            class="
              md:space-x-4 md:col-span-1 lg:mt-6
              lg:flex lg:items-center lg:col-span-2
            "
          >
            <div class="line-divider hidden lg:block w-full"></div>
            <button class="secondary lg:w-52" @click="summitProgramme()">
              <p>Summit Programme</p>
            </button>
          </div>
        </div>
        <img src="../../assets/images/nis/nis-bg.png" alt="" />
      </div>
    </section>
    <section id="sponsors" class="mt-20">
      <div class="flex items-center">
        <h4 class="tag subheader w-2/5">Sponsors</h4>
        <div class="line-divider w-full"></div>
      </div>
      <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6 my-16">
        <div class="bg-white flex items-center h-32 justify-center">
          <img src="../../assets/images/nis/keystone.png" alt="" />
        </div>
        <div class="bg-white flex items-center py-3 h-32 justify-center">
          <img
            class="object-contain w-full h-20"
            src="../../assets/images/nis/fmn.png"
            alt=""
          />
        </div>
        <div class="bg-white flex items-center py-6 h-32 justify-center">
          <img
            class="object-contain w-full h-20"
            src="../../assets/images/nis/ihs.png"
            alt=""
          />
        </div>
        <div class="bg-white flex items-center py-6 h-32 justify-center">
          <img
            class="object-contain w-full h-20"
            src="../../assets/images/nis/silvex.png"
            alt=""
          />
        </div>
      </div>
      <div
        class="
          space-x-4 col-span-1 flex items-center
          lg:col-span-2
        "
      >
        <div class="line-divider w-full"></div>
        <button class="secondary w-28">
          <p>And Others</p>
        </button>
      </div>
    </section>
  </div>
</template>

<script src="./investment-summit.page.js"></script>
<style src="./investment-summit.page.css" scoped lang="css"></style>